import React from "react";
import { navigateTo } from "../../../utils/navigationUtils";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import StarRating from "../../../helpers/StarRating";

const Card = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = () => {
    if (location.pathname.endsWith("/partners")) {
      navigateTo(navigate, "/partners/partner");
    } else {
      navigateTo(navigate, "/payment", true);
    }
  };

  return (
    <motion.div
      className="flex justify-center flex-wrap gap-[18px]  "
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {data.map((item) => {
        return (
          <motion.div
            key={item.id}
            className="w-[336px] md:w-[386px] h-[210px] md:h-[224px] flex flex-col border border-a010  rounded-[20px] shadow-lg cursor-pointer"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            onClick={handleNavigate}
          >
            <div className="h-[40px] rounded-t-[20px] bg-gradient-to-r from-[#EE5222]/25 to-[#FD906F]/50 z-0 relative"></div>
            {/* icon & status */}
            <div className="absolute pt-6 z-10 w-[336px] md:w-[386px]  px-4">
              <div className="flex justify-between items-end">
                <img
                  alt="partnerLogo"
                  src={item.img}
                  className="w-[64px] h-[64px] rounded-full"
                />
                <span className="h-[28px] w-[56px] rounded bg-a05 border border-a010 prose-t14_regular text-primary flex items-center justify-center">
                  {item.status}
                </span>
              </div>
              {/* company */}
              {/* stars */}
              <div className="flex items-center gap-2  pt-2.5">
                <span className="prose-t16_medium md:prose-t18_bold">
                  {item.company}
                </span>
                <StarRating count={item.stars} />{" "}
              </div>
              {/* description */}
              <div>
                <p className="prose-t16_regular text-a070">{item.descp}</p>
              </div>
              {/* service */}
              <div className="flex items-center gap-1.5 prose-t14_regular text-a050 mt-3">
                <span>{item.service}</span>
                <div className="h-1 w-1 rounded-full bg-[#666666]"></div>
                <span>{item.serviceCount}</span>
              </div>
            </div>
          </motion.div>
        );
      })}
    </motion.div>
  );
};

export default Card;
