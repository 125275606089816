import styles from "./styles/ComingSoonCountdown.module.css"; // Import CSS module

const ComingSoonCountdown = () => {
  return (
    <section
      className={`${styles["coming-soon"]} ${styles["coming-soon--animated-gradient"]} py-20`}
    >
      {/* Bubbles */}
      <div className={styles["coming-soon__bubble"]}></div>
      <div className={styles["coming-soon__bubble"]}></div>
      <div className={styles["coming-soon__bubble"]}></div>
      <div className={styles["coming-soon__bubble"]}></div>
      <div className={styles["coming-soon__bubble"]}></div>

      <div className="max-w-5xl mx-auto px-6 lg:px-8 flex flex-col items-center justify-center text-center">
        <img
          src="/assets/header-logo.png"
          alt="Company Logo"
          className="mb-8 w-32 lg:w-48"
        />
        <h2 className="text-4xl lg:text-5xl text-primary font-bold mb-8">
          Our Website is Coming Soon
        </h2>
        <p className="text-gray-700 text-lg lg:text-xl leading-relaxed mb-12">
          Book trustworthy services in one place{" "}
        </p>
        <a
          href="https://www.linkedin.com/company/myservice-am/posts/?feedView=all"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-8 text-gray-700 hover:underline"
        >
          Follow us on LinkedIn
        </a>
      </div>
    </section>
  );
};

export default ComingSoonCountdown;
