import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/sliderStyles.css";

const CustomerSlider = () => {
  const { t } = useTranslation();

  const lang = localStorage.getItem("i18nextLng");

  const userComments = [
    {
      id: 1,
      name: "Paylak Reinink",
      url: "assets/jonathan.jpg",
      comment: t("userReview1"),
    },
    {
      id: 2,
      name: "Narek Reinink",
      url: "assets/jonathan.jpg",
      comment: t("userReview2"),
    },
    {
      id: 3,
      name: "Petros Reinink",
      url: "assets/jonathan.jpg",
      comment: t("userReview3"),
    },
    {
      id: 4,
      name: "David Reinink",
      url: "assets/jonathan.jpg",
      comment: t("userReview4"),
    },
    {
      id: 5,
      name: "Hrair Reinink",
      url: "assets/jonathan.jpg",
      comment: t("userReview5"),
    },
    {
      id: 6,
      name: "Gor Reinink",
      url: "assets/jonathan.jpg",
      comment: t("userReview6"),
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="flex justify-center ">
        <h2 className="prose-t32_regular lg:prose-t40_regular">
          {t("what")}&nbsp;
        </h2>
        <h2 className="prose-t32_regular lg:prose-t40_bold text-primary">
          {t("customersSay")}
        </h2>
      </div>
      <Slider {...settings} className="slider-with-gap">
        {userComments.map((item) => (
          <div
            key={item.id}
            className={`${
              lang === "en" ? " h-[250px] lg:h-[235px] w-auto" : " "
            }min-w-[320px]  md:w-[386px] min-h-[170px] px-5 py-6 flex flex-col justify-center gap-4 shadow-md border border-a010 rounded-2xl`}
          >
            <div className="flex items-center">
              <img
                className="w-10 h-10 rounded-full mr-4"
                src={item.url}
                alt={`Avatar of ${item.name}`}
              />
              <div>
                <p className="prose-t14_bold ">{item.name}</p>
                <p className="prose-t12_regular text-a040 ">
                  Apple-certified trainer in digital media
                </p>
              </div>
            </div>
            <p className="prose-t14_regular pt-4">{item.comment}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CustomerSlider;
