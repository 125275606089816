import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { validationSchema } from "./validation/validationSchema";
import {
  errorMessageStyle,
  formInput,
} from "../../ui-elements/formStyles/formStyle";
import { primary } from "../../ui-elements/button";
import { sendMassage } from "../../axios/api/contactUsApi";
import { useDispatch } from "react-redux";
import {
  changePopUpStatus,
  setRequestStatus,
} from "../../redux/slices/popUp/popUpSlice";

const ContactForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async (values, { resetForm }) => {
    const responseData = await sendMassage(values);
    dispatch(setRequestStatus(responseData));
    dispatch(changePopUpStatus(true));
    resetForm();
  };

  return (
    <div className="py-5 ">
      <div className="flex pl-4 flex-col items-start justify-center gap-1  ">
        <h1 className="prose-t24_bold md:prose-t29_bold text-a060 ">
          {t("getInTouch")}
        </h1>
        <span className="prose-t14_regular text-a070">
          {t("youCanReachUs")}
        </span>
      </div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          help: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className=" mt-4 flex flex-col md:flex-row items-center justify-center md:gap-4">
            <div className="flex flex-col">
              <Field
                type="text"
                name="firstName"
                placeholder={t("firstName")}
                className={formInput}
              />
              <div className="min-h-6">
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className={errorMessageStyle}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <Field
                type="text"
                name="lastName"
                placeholder={t("lastName")}
                className={formInput}
              />
              <div className="min-h-6">
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className={errorMessageStyle}
                />
              </div>
            </div>
          </div>
          <div className=" flex justify-center gap-4">
            <div className="flex flex-col">
              <Field
                type="email"
                name="email"
                placeholder={t("yourEmail")}
                className=" w-[336px] md:w-[672px]  h-[56px] bg-a05 rounded-xl p-3 focus:outline-none"
              />
              <div className="min-h-6">
                <ErrorMessage
                  name="email"
                  component="div"
                  className={errorMessageStyle}
                />
              </div>
            </div>
          </div>
          <div className=" flex justify-center gap-4">
            <div className="flex flex-col">
              <Field
                type="text"
                name="phoneNumber"
                placeholder={t("phoneNumber")}
                className="w-[336px] md:w-[672px]  h-[56px] bg-a05 rounded-xl p-3 focus:outline-none"
              />
              <div className="min-h-6">
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className={errorMessageStyle}
                />
              </div>
            </div>
          </div>
          <div className="mb-5 flex justify-center">
            <div className="flex flex-col">
              <Field
                as="textarea"
                name="help"
                placeholder={t("howCanWeHelp")}
                style={{ resize: "none" }} // Block the height resizing
                className="w-[336px] md:w-[672px] h-[159px] bg-a05 rounded-xl p-3 focus:outline-none"
              />
              <div className="min-h-5">
                <ErrorMessage
                  name="help"
                  component="div"
                  className={errorMessageStyle}
                />
              </div>
            </div>
          </div>
          <div className="mb-2.5 flex justify-center">
            <button
              type="submit"
              className={` w-[320px] md:w-[328px] ${primary} `}
            >
              {t("submit2")}
            </button>
          </div>
        </Form>
      </Formik>
      <div className="flex justify-center ">
        <span className="prose-t14_bold text-[#5D5D5D] flex flex-col lg:flex-row gap-1.5 text-center">
          {t("byContactingUsYou")}
          <a
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className=" cursor-pointer text-black hover:text-a070"
          >
            {t("privacyPolicy1")}
          </a>
        </span>
      </div>
    </div>
  );
};

export default ContactForm;
