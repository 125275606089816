import React from "react";
import { useTranslation } from "react-i18next";
import OrderForm from "./OrderForm";

const Order = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex justify-center items-center ">
      <div className="lg:max-w-[588px] w-full py-[30px] lg:py-[65px]  flex flex-col justify-center items-center lg:items-start gap-2">
        <h2 className="prose-t24_bold text-center lg:text-start">
          {t("almostFinished")}
        </h2>
        <span className="text-a070 text-center lg:text-start">
          {t("almostThereConfirmYour")}
        </span>
        <OrderForm />
      </div>
    </div>
  );
};

export default Order;
