import React, { useState } from "react";
import PaymentQuestions from "../components/payment/PaymentQuestions";

const Payment = () => {
  const data = [
    {
      title: "What Kind of Cleaning Do You Want?",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      fields: [
        { field: "Entaciq maqrum", type: "radio" },
        { field: "himnakan maqrum ", type: "radio" },
        { field: "premium patet", type: "radio" },
        { field: "veranorogumic heto/bnakaran", type: "radio" },
        { field: "veranorogumic heto / komercion taracq", type: "radio" },
        { field: "korporativ aracharkner", type: "radio" },
        { field: "gorcvacqic papuk kahuyq", type: "radio" },
        { field: "kashve papuk kahuyq", type: "radio" },
        { field: "zanaveska", type: "radio" },
        { field: "hartuk", type: "radio" },
        { field: "ayc", type: "radio" },
      ],
    },
    {
      title: "What Kind of Cleaning Do You Want?",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      fields: [
        { id: 1, field: "Entaciq maqrum", type: "radio", checked: false },
        { field: "himnakan maqrum ", type: "radio" },
        { field: "premium patet", type: "radio" },
        { field: "veranorogumic heto/bnakaran", type: "radio" },
        { field: "veranorogumic heto / komercion taracq", type: "radio" },
        { field: "korporativ aracharkner", type: "radio" },
        { field: "gorcvacqic papuk kahuyq", type: "radio" },
        { field: "kashve papuk kahuyq", type: "radio" },
        { field: "zanaveska", type: "radio" },
        { field: "hartuk", type: "radio" },
        { field: "ayc", type: "radio" },
      ],
    },
  ];
  return (
    <div>
      <PaymentQuestions data={data} />
    </div>
  );
};

export default Payment;
