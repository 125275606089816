import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { primaryFIxed, secondaryFixed } from "../../ui-elements/button";

const PaymentQuestions = ({ data }) => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  // Filter to get the current step data based on the step number
  const currentStepData = data.find((item) => item.stepnumber === step);

  return (
    <div className="flex flex-col items-center justify-center py-[64px]">
      {currentStepData && (
        <div className=" flex flex-col gap-6">
          <div className="flex  flex-col gap-2">
            <h2 className="prose-t24_bold text-black">
              {currentStepData.title}
            </h2>
            <p className="prose-t16_regular text-a070">
              {currentStepData.description}
            </p>
          </div>

          <form className="flex flex-col gap-3">
            {currentStepData.fields.map((field, index) => (
              <div key={index} className="flex  gap-3 w-[414px] py-[14px]">
                {field.type === "checkbox" ? (
                  <>
                    <input type="checkbox" id={`checkbox-${index}`} />
                    <label
                      className="prose-t16_regular text-a080"
                      htmlFor={`checkbox-${index}`}
                    >
                      {field.field}
                    </label>
                  </>
                ) : field.type === "radio" ? (
                  <>
                    <input
                      type="radio"
                      name={`radio-group-${step}`}
                      id={`radio-${index}`}
                    />
                    <label
                      htmlFor={`radio-${index}`}
                      className="prose-t16_regular text-a080"
                    >
                      {field.field}
                    </label>
                  </>
                ) : null}
              </div>
            ))}
          </form>
        </div>
      )}

      {/* Navigation buttons */}
      <div className="flex mt-4 gap-2">
        <button
          className={secondaryFixed}
          disabled={step === 1}
          onClick={() => setStep((prev) => prev - 1)}
        >
          Previous
        </button>
        <button
          className={primaryFIxed}
          disabled={step === data.length}
          onClick={() => setStep((prev) => prev + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PaymentQuestions;
